import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function MenuBoxes() {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      markdownRemark {
        frontmatter {
          menus {
            id
            title
            description
            href
            imgSrc {
              childImageSharp {
                gatsbyImageData(
                  width: 640
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `);

  const { menus } = data.markdownRemark.frontmatter;

  return (
    <div className=" relative mx-auto max-w-7xl bg-background px-6 pt-16 lg:px-8 lg:pt-24">
      <div className="relative mx-auto ">
        <div className="mx-auto flex max-w-md flex-col text-center lg:max-w-2xl">
          <p className="font-base  pb-1 text-base leading-7 text-white">
            Takeout Available
          </p>
          <h2 className="mx-auto font-ibm text-3xl font-bold tracking-tight text-white sm:text-5xl">
            <div className="flex flex-col lg:flex-row">
              <div>Open for </div>
              <div className="mt-1 ml-0 lg:mt-0 lg:ml-2">Breakfast & Lunch</div>
            </div>
          </h2>
          <p className="mx-auto pt-2 text-lg tracking-tight text-white sm:text-lg">
            We use not only excellent fresh, seasonal products but also much of
            what is “best in Elgin”.
          </p>
        </div>
        <div className="mx-auto mt-12 grid gap-5 lg:max-w-none lg:grid-cols-2">
          {menus.map((menu) => (
            <div
              key={menu.id}
              className="flex flex-col overflow-hidden shadow-lg"
            >
              <div className="flex flex-col bg-white p-6">
                <div className="mx-auto flex-1">
                  <a href={menu.href} className="mt-2 block">
                    <p className="font-ibm text-3xl font-semibold text-gray-900 lg:text-2xl">
                      {menu.title} Menu
                    </p>
                  </a>
                </div>
              </div>
              <div className="flex-shrink-0 bg-white px-5">
                <GatsbyImage
                  className="w-full object-cover sm:h-96"
                  image={menu.imgSrc.childImageSharp.gatsbyImageData}
                  alt=""
                  aspectRatio={4 / 3}
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <a href={menu.href} className="mt-2 block text-center">
                    {menu.description.map((text) => (
                      <p className="mt-3 text-base text-gray-500">{text}</p>
                    ))}
                  </a>
                </div>
                <div className="flex-1 pt-5 text-center">
                  <a href={menu.href} className="hover:underline">
                    <div className="inline-flex border-2 border-secondary p-2  text-secondary">
                      View our {menu.title} Menu
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
