import React from 'react';

import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import Banner from './banner';

export default function Contact() {
  return (
    <div className="mx-auto max-w-7xl bg-background">
      <div className=" flex flex-wrap items-start justify-start pt-2 sm:py-10 sm:px-4 md:flex-row lg:px-8">
        <div className="  mx-auto flex w-full  px-4 pb-10 sm:w-7/12 md:px-8 md:pb-2 lg:w-5/12">
          <div className="mx-auto flex w-full  flex-col justify-start">
            <h3 className="font-ibm text-3xl  font-normal text-white sm:text-3xl">
              Contact information
            </h3>
            <dl className="space-y-6 ">
              <dt>
                <span className="sr-only">Phone number</span>
              </dt>
              <dd className="flex text-lg text-gray-50">
                <PhoneIcon
                  className="h-6 w-6 flex-shrink-0 text-gray-200"
                  aria-hidden="true"
                />
                <span className="ml-3">519 633-8181</span>
              </dd>
              <dt>
                <span className="sr-only">Email</span>
              </dt>
              <dd className="flex text-lg text-gray-50">
                <EnvelopeIcon
                  className="h-6 w-6 flex-shrink-0 text-gray-200"
                  aria-hidden="true"
                />
                <span className="ml-3">rgocan@gmail.com</span>
              </dd>
              <dd className="flex text-lg text-gray-50">
                <MapPinIcon
                  className="h-6 w-6 flex-shrink-0 text-gray-200"
                  aria-hidden="true"
                />
                <div className="flex flex-col">
                  <span className="ml-3">310 Wellington St.</span>
                  <span className="ml-3">St. Thomas, ON N5R 2T1 </span>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className=" mx-auto flex w-full px-4 pb-10 sm:w-5/12 md:order-first md:px-8 md:pb-2 lg:w-3/12">
          <div className="flex  flex-col  justify-start">
            <div className="flex flex-col">
              <h3 className="pb-4 font-ibm text-3xl font-normal text-white  sm:text-3xl">
                Hours
              </h3>
              <dl className="space-y-2 ">
                <dd className="flex text-xl text-gray-50">
                  <span className="">Tue</span>
                  <span className="ml-5">7am - 2pm</span>
                </dd>
                <dd className="flex text-xl text-gray-50">
                  <span className="">Wed</span>
                  <span className="ml-3">7am - 2pm</span>
                </dd>
                <dd className="flex text-xl text-gray-50">
                  <span className="">Thu</span>
                  <span className="ml-5">7am - 2pm</span>
                </dd>
                <dd className="flex text-xl text-gray-50">
                  <span className="">Fri</span>
                  <span className="ml-8">7am - 2pm</span>
                </dd>
                <dd className="flex text-xl text-gray-50">
                  <span className="">Sat</span>
                  <span className="ml-6">7am - 2pm</span>
                </dd>
                <dd className="flex flex-col border-t-2 text-xl text-gray-50">
                  <div className="flex flex-row pt-4">
                    <span className="">Sun </span>
                    <span className="ml-4">7am - 2pm</span>
                  </div>
                  <span className="pt-1 text-center text-lg">
                    (Breakfast Only)
                  </span>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="  mx-auto flex w-full px-4   md:w-full md:px-8 md:pt-6 md:pb-2 lg:w-4/12 lg:pt-0">
          <div className="flex flex-col overflow-hidden">
            <h3 className="pb-4 font-ibm text-3xl font-normal  text-white">
              Payment Options
            </h3>
            <p className="pb-4   text-lg text-white ">
              Cash, debit, and ATM bank machine on site.
            </p>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  );
}
